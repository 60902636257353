import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react"

export const CardFooter = props => {
    let { bg, color } = props;
    let modeValue = useColorModeValue("gray.700", "gray.200")

    bg = bg || "inherit";
    color = color || modeValue;

    return (
        <Box>
            <Flex
                alignItems="center"
                justifyContent={"center"}
                pt={2}
                pb={2}
                bg={bg}
            >
                <Box>
                    <Text
                        color={ color }
                        fontSize={"xs"}>
                        {props.children}
                    </Text>
                </Box>
            </Flex>
        </Box>
    )
}

export default CardFooter
