import { chakra, Box, Text, useColorModeValue } from "@chakra-ui/react";
import InlineBreak from "../common/InlineBreak";

import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';

const message = `\
공구교육끝판왕 꿈을더하다에 오신 것을 환영합니다.

인스타그램은 여러분의 직장이자 가게예요.
이곳을 어떻게 가꾸고 꾸미냐에 따라 여러분의 수익이 달라집니다!

매주 열리는 올림픽이 큰 동기부여가 돼줄 거예요
이제 인스타 마케팅을 배우며 다양한 상품도 함께 받아 가세요!\
`

const mobileMessage = `\
공구교육끝판왕 꿈을더하다에  
오신 것을 환영합니다.

인스타그램은 여러분의 직장이자 가게예요.
이곳을 어떻게 가꾸고 꾸미냐에 따라 여러분의 수익이 달라집니다!

매주 열리는 올림픽이 큰 동기부여가 돼줄 거예요
이제 인스타 마케팅을 배우며 다양한 상품도 함께 받아 가세요!\
`

const newTheme = {
    p: props => {
        const { children } = props;
        return <Text
            mb={6}
            fontSize={{ base: "lg", md: "xl" }}
            lineHeight="base"
            color={"gray.500"}>{children}</Text>;
    },
}


export const Hero = ({ section }) => {

    let color = useColorModeValue("gray.900", 'gray.100')

    if (section) return null

    return (

        <Box
            textAlign={"center"}
            wordBreak={"keep-all"}
            pt={[ 0, 3, 5, 8 ]}
            bg={"gray.50"}
            px={[ 6, 8 ]}
            py={[ 4, 6 ]} >
            <chakra.h1
                py={6}
                fontSize={{ base: "4xl", md: "4xl", lg: "5xl" }}
                fontWeight="bold"
                lineHeight="1.15"
                letterSpacing={{ base: "normal", md: "tight" }}
                color={ color }
            >
                <Text as={"span"}
                    // display={{ base: "block", lg: "block" }}
                      w="full"
                      bgClip="text"
                    // bgGradient="linear(to-r, green.400, blue.600)"
                    // bgGradient="linear(to-r, facebook.600, facebook.500, facebook.400, facebook.600)"
                      bgGradient={"linear(to-r, gradient.green, gradient.blue)"}
                    //   bgGradient={"linear(to-r, olympics.blue, olympics.yellow, olympics.black, olympics.green, olympics.red)"}
                      fontWeight="extrabold"
                      lineHeight="1.25"
                >꿈팸올림픽</Text>에<InlineBreak/> 오신 걸 환영해요!
            </chakra.h1>
            <Box display={[ "none", "block" ]}>
                <ReactMarkdown
                    components={ChakraUIRenderer(newTheme)}
                    children={message}
                />
            </Box>
            <Box display={[ "block", "none" ]} sx={{ wordBreak: "keep-all" }}>
                <ReactMarkdown
                    components={ChakraUIRenderer(newTheme)}
                    children={mobileMessage}
                />
            </Box>
        </Box>
    )
}


export default Hero
