import { Box } from "@chakra-ui/react"

export const Layout = ({ children }) => (
    <Box p={0} mx="auto">
        <Box

            mx="auto"
            textAlign={{ base: "center", md: "center" }}
        >
        { children }
        </Box>
    </Box>
)

export default Layout