import { useState, useEffect } from "react"
import moment from "moment"

export const useCurrentContests = props => {

    let { firebase, collections } = props;
    let [currentContests, setCurrentContests] = useState(null);
    let [currentContestsDate, setCurrentContestsDate] = useState(null);

    useEffect(() => {
        let getCurrentContests = async () => {
            let db = firebase.firestore(),
                col = db.collection(collections.contests),
                query = col.orderBy("ordering_index")
                    .where("current", "==", true),
                snapshot = await query.get(),
                contests = [],
                date = null;

            snapshot.forEach(contest => {
                date = contest.get("date");
                contests.push({
                    ...contest.data(),
                    start: moment(contest.get("start").toDate()),
                    end: moment(contest.get("end").toDate()),
                })
            });
            setCurrentContests(contests);
            setCurrentContestsDate(date);
            // console.log(contests)
        }
        try {
            getCurrentContests();
        } catch (err) { console.log(err)}
    }, [firebase, collections.contests])

    return { contests: currentContests, date: currentContestsDate };
}

export default useCurrentContests