import React, { useMemo } from "react"
import { Box, SimpleGrid } from "@chakra-ui/react"

import Leaderboards from "./leaderboards/Leaderboards"
import Winners from "./winners/Winners"
import Prizes from "./prizes/Prizes"
import Tips from "./tips/Tips"

import useCurrentContests from "../hooks/useCurrentContests"
import useCurrentEntries from "../hooks/useCurrentEntries"
import usePreviousContests from "../hooks/usePreviousContests"
import usePreviousEntries from "../hooks/usePreviousEntries"


export const Contests = ({ collections, firebase, section }) => {

    const noOfWinnersPerContest = 10

    let { contests, date } = useCurrentContests(useMemo(() =>
            ({ firebase, collections }),
        [ firebase, collections ]))

    let { entries } = useCurrentEntries(useMemo(() =>
            ({ firebase, collections, date }),
        [ firebase, collections, date ]))

    let { winners, previousDate } = usePreviousContests(useMemo(() =>
            ({ firebase, collections }),
        [ firebase, collections ]))

    let { previousEntries } = usePreviousEntries(useMemo(() =>
            ({ firebase, collections, date: previousDate }),
        [ firebase, collections, previousDate ]))


    let onRender = (contests, entries, winners, previousEntries) => {
        let contestsNotEmpty = contests && contests.length !== 0,
            entriesNotEmpty = entries && entries.length !== 0,
            winnersNotEmpty = winners && winners.length !== 0,
            previousEntriesNotEmpty = previousEntries && previousEntries.length !== 0;

        if (section) {
            switch (section) {
                case "prizes":
                    if (contestsNotEmpty) {
                        return <Prizes contests={contests}/>
                    }
                    break;
                case "winners":
                    if (winnersNotEmpty && previousEntriesNotEmpty) {

                        return <>
                            {
                                winners.reduce((sum, contest) => {
                                    sum += contest.winners.length;
                                    return sum
                                }, 0)
                                    ?
                                    <Winners
                                        firebase={ firebase } collections={ collections }
                                        contests={ winners } entries={ previousEntries }/>
                                    :
                                    null
                            }
                        </>
                    }
                    break;
                default:
                    break;
            }
        }

        if (contestsNotEmpty && entriesNotEmpty && winnersNotEmpty && previousEntriesNotEmpty) {
            return (
                <Box
                    className="contests-bg"
                    bgColor={["gray.50"]}>
                    <SimpleGrid
                        className="contests-grid"
                        columns={[1, null, 2  ]}
                        spacing={[1, null, 4]}>
                        <Prizes contests={contests} />
                        {
                            winners.reduce((sum, contest) => {
                                sum += contest.winners.length;
                                return sum
                            }, 0)
                                ?
                                <Winners
                                    firebase={ firebase } collections={ collections }
                                    contests={ winners } entries={ previousEntries }/>
                                :
                                null
                        }
                    </SimpleGrid>
                    <Leaderboards
                        contests={contests}
                        entries={entries}
                        listSize={noOfWinnersPerContest}/>

                    <Leaderboards
                        isPrevious={true}
                        contests={winners}
                        entries={previousEntries}
                        listSize={noOfWinnersPerContest}/>
                </Box>

            )
        } else if (contestsNotEmpty && entriesNotEmpty) {
            return (
                <Box className="contest-bg" bgColor={["gray.50", "white"]}>
                    <SimpleGrid className="grid" columns={[ 1, null, 2 ]} spacing={4} >
                        <Prizes contests={contests} />
                        <Tips/>
                    </SimpleGrid>
                    <Leaderboards
                        contests={contests}
                        entries={entries}
                        listSize={noOfWinnersPerContest}/>
                </Box>
            )
        } else if (winnersNotEmpty && previousEntriesNotEmpty) {
            return (
                <Box className="contest-bg" bgColor={["gray.50", "white"]}>
                    <SimpleGrid
                        className="contests-grid"
                        columns={[1, null, 2  ]}
                        spacing={[1, null, 4]}>
                        {
                            winners.reduce((sum, contest) => {
                                sum += contest.winners.length;
                                return sum
                            }, 0)
                                ?
                                <Winners
                                    firebase={ firebase } collections={ collections }
                                    contests={ winners } entries={ previousEntries }/>
                                :
                                null
                        }
                        <Tips/>
                    </SimpleGrid>
                    <Leaderboards
                        isPrevious={true}
                        contests={winners}
                        entries={previousEntries}
                        listSize={noOfWinnersPerContest}/>
                </Box>
            )
        }

        else if (contestsNotEmpty) {
            return (
                <Box className="contest-bg" bgColor={["gray.50", "white"]}>
                    <SimpleGrid className="grid" spacing={4} columns={[ 1, null, 2  ]}>
                        <Prizes contests={contests} />
                        <Tips/>
                    </SimpleGrid>
                </Box>

            )
        }
        return null
    }

    return (
        <div>
            {onRender(contests, entries, winners, previousEntries)}
        </div>

    )

}

export default Contests;
