import { Flex, Box, Text, Image, VStack, Divider } from "@chakra-ui/react"

import logo from "../../assets/img/logo-short-white-square.png"

export const CardHeader = props => {
    let { title, subtitle, isPrevious } = props;

    let color = isPrevious ? "olympics.info" : "olympics.danger";

    return (
        <VStack
            justify={"center"}
            pt={1}
            borderColor={"white"}
            borderTopRadius={"md"}
            align={"center"}
            bg={color}

        >
            {
                isPrevious ?
                    <Text
                        color={"white"}
                        as={"h3"}
                        fontWeight={"bold"}
                        fontSize={"lg"}
                    >
                        {"✨✨✨✨ 지난 결과 ✨✨✨✨"}
                    </Text>
                    : null
            }
            {
                isPrevious ? <Divider color={"whiteAlpha.50"}/> : null
            }
            <Flex
                pt={1}
                pr={1}
                pl={3}
                pb={4}
                w={"full"}
                justify={"space-between"}
            >
                <Box>

                    <Text
                        color={"white"}
                        as={"h3"}
                        fontWeight={"bold"}
                        fontSize={"lg"}
                    >
                        {title}
                    </Text>
                    {
                        subtitle ?
                            <Text mt={1}
                                  color={"white"}
                                  fontSize={"sm"}>{subtitle}</Text>
                            : null
                    }
                </Box>
                <Image src={logo} w={"55px"} h={"auto"}/>
            </Flex>
        </VStack>

    )
}

export default CardHeader
