import { Box, Flex, Image, Spacer } from "@chakra-ui/react";
import logo from "../../assets/img/logo-full-black.png";
import rings from "../../assets/img/olympic-rings.png";

export const Header = () => (
    <Flex bg={"white"} w={"full"} mx={"auto"}
         shadow={"2xl"}>
        <Flex justifyContent={"space-between"}
            // maxH={["120px"]}
            // maxHeight={"100px"}
            mx={"auto"}
              px={[ 6, 8 ]}
              py={[ 4, 6 ]}
              w={{ base: "full", md: 11 / 12, xl: 9 / 12 }}
        >
            <Box w={[ "45vw", "25vw" ]} >
                <Image
                    src={logo}
                    alt="dream-of-more"
                    maxH={"75px"}

                />
            </Box>
            <Spacer/>
            <Flex w={[ "35vw", "25vw" ]} maxWidth={"280px"} justify={"flex-end"}>
                <Image src={rings} alt={"olympics"} maxH={"75px"}/>
            </Flex>
        </Flex>
    </Flex>

)

export default Header
