import { useParams, Redirect } from "react-router-dom"
import { Box } from "@chakra-ui/react"

import Layout from "../components/layout/Layout"
import Header from "../components/header/Header"
import Hero from "../components/hero/Hero"
import Contests from "../components/Contests"

import "../assets/css/line-awesome.min.css"
import "./Olympics.css"


export const Olympics = props => {

    const collections = {
        shoplink: {
            contests: "shoplink_contests",
            entries: "shoplink_entries",
            subscribers: "shoplinkers"
        }
    }

    let { contest, section } = useParams();

    if (!collections[contest]) return <Redirect to={"/"}/>;

    let { firebase } = props;

    return (
        <Box>
            <Layout>
                <Header/>
                <Hero section={section}/>
            </Layout>
            <Box className={"contests"}
                 w={["full", null, null, 10 / 12, 7 / 12]}
                 px={[ 2, 4 ]}
                 pt={[ 0, 6 ]}
                 pb={[ 6, 6 ]}
                 mx="auto">
                <Contests
                    firebase={firebase}
                    collections={ collections[contest]}
                    section={ section }
                />
            </Box>
        </Box>


    )
}

export default Olympics