import { Divider, Box } from "@chakra-ui/react"

import GridItem from "../common/GridItem"
import Card from "../common/Card"
import OlympicHeader from "../common/OlympicHeader"
import Prize from "./Prize"
import CardFooter from "../common/CardFooter"
import moment from "moment";

export const Prizes = props => {

    let title = "✨🎁 Prizes 🎁✨";

    let { contests } = props;

    let ends = moment(contests[0].end).format("YYYY년MM월DD일 HH:mm")

    return (
        <GridItem>
            <Card border={"gradient.blue"}>
                <OlympicHeader title={title} bg={"gradient.blue"}/>
                <Divider orientation={"horizontal"}/>
                <Box>
                    {contests.map((contest, idx) => <Prize key={contest.id} contest={contest}
                                                           isLast={(idx === contests.length - 1)}/>)}
                </Box>
                <CardFooter bg="white" color={"gradient.blue"}>
                    Contest Ends: {ends}
                </CardFooter>
            </Card>
        </GridItem>
    )
}

export default Prizes
