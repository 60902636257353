import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import "@fontsource/open-sans";
import "@fontsource/noto-sans-kr";
import "@fontsource/poppins";
// import "@fontsource/nanum-gothic"
// import "@fontsource/do-hyeon"
// import "@fontsource/roboto"

// import "./assets/css/nucleo-icons.css";
// import "./assets/css/nucleo-svg.css";

// import "./assets/scss/argon-design-system.scss?v1.0.0";


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


