import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/analytics';

let firebaseConfig = {
    apiKey: "AIzaSyDChZEpPD5DmFI_kdwsYfytyIPn-SV4AuM",
    authDomain: "supersimpleshop-71b5f.firebaseapp.com",
    databaseURL: "https://supersimpleshop-71b5f.firebaseio.com",
    projectId: "supersimpleshop-71b5f",
    storageBucket: "supersimpleshop-71b5f.appspot.com",
    messagingSenderId: "241290902224",
    appId: "1:241290902224:web:899d12d10708e2b6957cd5",
    measurementId: "G-F0E84HY82H"
};
// Initialize Firebase

export class Fuego {
    constructor(config) {
        let initialized = firebase.apps.length;
        this.db = !initialized
            ? firebase.initializeApp(config).firestore()
            : firebase.app().firestore();
        this.auth = firebase.auth;
        this.functions = firebase.functions;
        this.storage = firebase.storage;
        this.firestore = firebase.firestore;

        if (!initialized) this.db.enablePersistence()
    }
}

export const initFirebase = () => {
    // try {
    //     return firebase.initializeApp(firebaseConfig);
    // } catch (err) {
    //     return firebase.app()
    // }
    return new Fuego(firebaseConfig)

}

export default initFirebase;


