import { SimpleGrid } from "@chakra-ui/react"
import Leaderboard from "./Leaderboard"

export const Leaderboards = props => {
    let { contests, ...rest } = props;

    return (
        <SimpleGrid className="leaderboards" spacing={[1, null, 4]} columns={[ 1, null, 2 ]}>
            {
                contests.map(contest => {
                    return <Leaderboard key={contest.id}
                                        contest={contest}
                                        {...rest} />
                })
            }
        </SimpleGrid>
    )
}

export default Leaderboards
