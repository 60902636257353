import { useState, useEffect } from "react"
import moment from "moment"

export const usePreviousContests = props => {

    let { firebase, collections } = props;

    let [ previousContests, setPreviousContests ] = useState(null)
    let [ previousContestsDate, setPreviousContestsDate] = useState(null);


    useEffect(() => {

        let getPreviousContests = async () => {
            let db = firebase.firestore(),
                col = db.collection(collections.contests),
                query = col.orderBy("ordering_index")
                    .where("previous", "==", true),
                snapshot = await query.get(),
                contests = [],
                date = null;
            snapshot.forEach(contest => {
                date = contest.get("date");
                // if (contest.get("winners") && contest.get("winners").length > 0) {
                contests.push({
                    ...contest.data(),
                    start: moment(contest.get("start").toDate()),
                    end: moment(contest.get("end").toDate()),
                })
                // }
            })
            let sentry = contests.filter(contest => !contest.current)
            setPreviousContests(sentry);
            setPreviousContestsDate(date);
        }

        try {
            getPreviousContests();
        } catch (err) {
            console.log(err)
        }

    }, [firebase, collections.contests, collections.entries]);

    return { previousContests, previousDate: previousContestsDate, winners: previousContests}
}

export default usePreviousContests
