import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";

const theme = extendTheme({
    fonts: {
        heading: '-apple-system, "Noto Sans KR", sans-serif',
        body: '-apple-system, "Noto Sans KR", sans-serif',
    },
    colors
})

export default theme
