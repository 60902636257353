import { Flex } from "@chakra-ui/react"

import Rank from "./Rank"
import UserCard from "./UserCard"
import Score from "./Score"

export const Entry = props => {
    let { contest, entrant, idx, instagram_username } = props;
    let index = idx.length === 1 ? ` ${idx + 1}` : idx + 1;

    return (
        <Flex
            className={"entry"}
            justify={"space-between"}
            align={"center"}
            py={1}>
            <Flex>
                <Rank rank={ index } />
                <UserCard user={ entrant } instagram_username={ instagram_username }/>
            </Flex>
            <Score score={ entrant[`${contest.metric}_str`] } />
        </Flex>
    )
}

export default Entry
