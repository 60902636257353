import { Box, Flex, useColorModeValue } from "@chakra-ui/react"

import PrizeImage from "./PrizeImage"
import PrizeTitle from "./PrizeTitle"
import PrizeDescription from "./PrizeDescription"


export const Prize = ({ contest, isLast }) => {
    return (
        <Flex
            maxW="md"
            mx="auto"
            bg={useColorModeValue("white", "gray.800")}
            borderBottom={isLast ? 0 : "1px"}
            borderColor="gray.200"
            // overflow="hidden"
        >
            <PrizeImage img={ contest.prize_image_url }/>

            <Box w={2 / 3} p={4} wordBreak={"keep-all"}>
                <PrizeTitle title={ contest.label }/>
                <PrizeDescription color={"gray.400"}>
                    { contest.description.replace("엘팸", "꿈팸") }에게 {contest.prize_label}
                </PrizeDescription>
            </Box>
        </Flex>
    )
}

export default Prize
