import { Box, chakra, Flex, Image } from "@chakra-ui/react";

import logo from "../../assets/img/logo-short-white-square.png";
import rings from "../../assets/img/olympic-rings-white.png";


export const OlympicHeader = props => {

    let { title, color, bg } = props;
    color = color || "white";
    bg = bg || "brand.400"

    return (
        <Flex
            justifyContent="space-between"
            alignItems="center"
            px={2}
            py={1}
            bg={ bg }
            roundedTop={"md"}
        >
            <Box h={"auto"} w={10}>
                <Image src={logo}/>
            </Box>
            <chakra.span
                fontSize="md"
                fontWeight={"bold"}
                color={ color }
                // color={useColorModeValue("gray.800", "gray.400")}
            >
                {title}
            </chakra.span>
            <Box h={"auto"} w={10}><Image src={rings}/></Box>

        </Flex>
    )
}

export default OlympicHeader
