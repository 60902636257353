import { Flex, Text, Divider, Box } from "@chakra-ui/react"
import UserCard from "../leaderboards/entry/UserCard"
import PrizeImage from "../prizes/PrizeImage"
import PrizeTitle from "../prizes/PrizeTitle"

import useSubscriber from "../../hooks/useSubscriber"

export const WinnerCard = props => {
    let { winner, unmask } = props;
    let { firebase, collections } = props;

    let subscriber = useSubscriber({ entry: winner, unmask, firebase, collections })

    return (
        <Box
            border={"1px solid"}
            borderColor={"gray.200"}
            bg={"gray.100"}
            rounded={"md"}
            px={2}
        ><UserCard py={1} user={winner} unmask={ unmask } subscriber={ subscriber }/></Box>
    )
}

export const Winner = props => {
    let { contest, entries, unmask, firebase, collections } = props;

    return (
        <Flex direction={"column"}>
            <Flex shrink={0} >
                <PrizeImage img={ contest.prize_image_url } />
                <Flex w={2 / 3} p={3} wordBreak={"keep-all"} direction={"column"}>
                    <PrizeTitle title={ contest.label } fontSize={"md"}/>
                    <Text as="span" color="gray.400"
                          mb={2} fontSize={"xs"}>
                        {contest.description}에게 {contest.prize_label}</Text>
                    {(contest.winners || []).map(id => {
                        let winner = entries.find(entry => entry.id === id);
                        return <WinnerCard key={winner.id}
                                           firebase={ firebase } collections={collections}
                                           winner={ winner } unmask={unmask} />
                    })}
                </Flex>
            </Flex>
            <Divider orientation={"horizontal"}/>
        </Flex>
    )
}

export default Winner
