
import { Flex, Image, Text } from "@chakra-ui/react";

export const UserCard = ({ user, unmask, subscriber, instagram_username, ...rest }) => {

    return (
    <Flex {...rest}>
        <Flex
            className="user-pic"
            shrink={0}
            mx={1}
            mt={[ 1, ]}
            mb={1}
        >
            <Image
                boxSize={[ "50px", "50px" ]}
                borderRadius={"full"}
                src={user.image_url}
            />
        </Flex>
        <Flex
            // grow={1}
            direction={"column"}
            pl={2}
            pb={2}
            pt={2}

            overflow={"hidden"} whiteSpace={"nowrap"} pr={3} textOverflow={"ellipsis"}
        >
            {(!unmask && instagram_username && user.username === instagram_username) ? <Text fontSize={[ "sm" ]}
                             fontWeight={"bold"}>{instagram_username}</Text> : null}
            {(!unmask && (!instagram_username || user.username !== instagram_username)) ? <Text fontSize={[ "sm" ]}
                             fontWeight={"bold"}>{user.nickname}</Text> : null}
            {unmask ? <Text fontSize={[ "sm" ]}
                            fontWeight={"bold"}>{subscriber?.name || user.name}</Text> : null}
            {unmask ? <Text fontSize={[ "sm" ]} color={"gray.600"}>샵링크: {user.subscriber_id}</Text> : null}
            <Text color={"gray.400"} fontSize={"sm"}>팔로워: {user.followers_count} {" "}
                {"🏅".repeat(Math.min((user.wins?.length || 0), 2))}
            </Text>
        </Flex>
    </Flex>

)}

export default UserCard
