import { Box, useColorModeValue } from "@chakra-ui/react"


export const Card = props => {
    let { border, children, ...rest } = props;
    let borderStyle = border ? {
        borderTop: "2px",
        borderColor: border,
        borderTopColor: border,
        borderBottom: "1px",
        borderBottomColor: border
    } : {};
    return (
        <Box
            className={"card"}
            w="full"
            maxW="sm"
            bg={useColorModeValue("white", "gray.800")}
            shadow="md"
            rounded="md"
            {...borderStyle}
            borderTopColor={"white"}
            mb={4}
            {...rest}
        >
            {  children }
        </Box>
    )
}

export default Card
