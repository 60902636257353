import { chakra, useColorModeValue } from "@chakra-ui/react";

export const PrizeTitle = ({ title, children, ...rest }) => (
    <chakra.h2
        fontSize="lg"
        fontWeight="bold"
        color={useColorModeValue("gray.700", "white")}
        { ...rest }
    >
        {title || children}
    </chakra.h2>
)

export default PrizeTitle
