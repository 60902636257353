import { chakra, useColorModeValue } from "@chakra-ui/react";

export const PrizeDescription = ({ description, children, ...rest }) => (
    <chakra.p
        fontSize="sm"
        mt={2}
        color={useColorModeValue("gray.500", "gray.300")}
        {...rest}
    >
        { description || children }
    </chakra.p>
)

export default PrizeDescription
