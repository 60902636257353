import { Badge, Box, Text } from "@chakra-ui/react";

export const Score = ({ score }) => (
    <Box className="user-score" textAlign={"center"}
         w={"60px"} h={"45px"}>
        <Badge ml="1"
               verticalAlign={"middle"}
               bgColor={"gray.100"}
               colorScheme="facebook"
               fontSize={"xl"}
               fontWeight={"normal"}
               display={"flex"}
               justifyContent={"center"}
               alignItems={"center"}
               shrink={0}
               position={"relative"}
               borderRadius=".42rem"
               w={"100%"}
               h={"100%"}
        >
            <Text as={"span"}
                  fontFamily={"Poppins"}
                  fontWeight={"bold"}
                  fontSize={"sm"}
                  color={"gradient.blue"}
                // color={"olympics.blue"}
            >
                { score }
            </Text>
        </Badge>
    </Box>
)

export default Score
