import { useLocation } from "react-router-dom"
import GridItem from "../common/GridItem"
import Card from "../common/Card"
import OlympicHeader from "../common/OlympicHeader"
import Winner from "./Winner"

export const Winners = props => {
    let { contests, entries, firebase, collections } = props;

    let location = useLocation()
    let unmask = location.pathname.includes("unmask")

    let title = "🥂🏆 Contest Winners 🏆🥂"


    return (
        <GridItem>
            <Card>
                <OlympicHeader
                    title={ title }
                    bg={"olympics.black"}/>
                {
                    contests.map(contest =>
                        <Winner
                            key={contest.id}
                            contest={contest}
                            entries={entries}
                            unmask={ unmask }
                            firebase={ firebase }
                            collections={ collections }
                        />)
                }
            </Card>
        </GridItem>
    )
}

export default Winners
