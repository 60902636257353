import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react"
import { ImQuotesLeft } from "react-icons/im"

const tip = `\
여러분, 소셜 미디어 채널의 목표는 잠재 고객과 진실되고 진정한 관계를 맺는데 있다는 점 기억해주시고 어떤 경우에도 커뮤니티 가이드라인을 위반하지 말아주세요.
`

export const Tips = () => (
    <Box
        display={["none", "block"]}
        w={"full"}
        maxW="sm"
        justifyItems="center"
        justifyContent="center"
        justifySelf={[ "center" ]}
        mb={6}
        borderTop={"2px"}
        borderTopColor={"gradient.green"}
    >
        <Flex direction={"column"}
              height={"100%"}>
            <Box
                flex={"1 1 0%"}
                borderRadius={"lg"}
                boxShadow={"base"}
                bg={useColorModeValue("white", "gray.800")}
                px={10}
                pt={8}
                pb={12}
                position={"relative"}

            >
                <Icon as={ImQuotesLeft} color={"gradient.green"} fontSize={"3xl"}/>
                <Text
                    mt={5}
                    fontSize={"lg"}
                    fontWeight={"bold"}
                    wordBreak={"keep-all"}>{ tip }</Text>

            </Box>
        </Flex>
    </Box>
)

export default Tips
