import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { ChakraProvider, Box } from "@chakra-ui/react"
import theme from "./theme";

import initFirebase from "./initFirebase";

import Olympics from "./pages/Olympics";
import './App.css';

const firebase = initFirebase();
const ElMarket = {};

const App = () => {
    return (
        <ChakraProvider theme={ theme }>
            <Box className={"OlympicsAppDiv"} p={0} mx={"auto"} bg={"gray.50"}>
                <Router>
                    <Switch>
                        <Route exact path="/unmask/:contest">
                            <Olympics market={ElMarket} firebase={firebase}/>
                        </Route>
                        <Route path="/:contest/u/:instagram_username">
                            <Olympics market={ElMarket} firebase={firebase}/>
                        </Route>
                        <Route path="/:contest/s/:section">
                            <Olympics market={ElMarket} firebase={firebase}/>
                        </Route>
                        <Route path="/:contest">
                            <Olympics market={ElMarket} firebase={firebase}/>
                        </Route>
                        <Redirect to={"/shoplink"} />
                    </Switch>
                </Router>
            </Box>
        </ChakraProvider>


    )
}

export default App
