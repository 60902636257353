import { useParams } from "react-router-dom"
import { Box, Divider } from "@chakra-ui/react"
import _ from "lodash"

import Entry from "./entry/Entry"

export const LeadersList = props => {

    let { instagram_username } = useParams()
    let { contest, entries, size } = props;

    let usernameSort = e => e.username === instagram_username ? 1 : 0;

    let leaderboardListSize = size,
        sortedList = _.orderBy(entries,[contest.metric, usernameSort], ["desc", "desc"]),
        rankedList = sortedList.map((entry, rank) => ({ ...entry, rank })),
        topLeaders = _.slice(rankedList, 0, leaderboardListSize)

    if (instagram_username && !topLeaders.find(leader => leader.username === instagram_username)) {

        let user = rankedList.find(entry => entry.username === instagram_username)
        if (user) topLeaders.push(user)
    }


    return (
        <Box
            className={"leaders-list"}

        >
            { topLeaders.map((entrant) => {

                    let bgColor = (instagram_username &&
                        entrant.username === instagram_username) ? "purple.100" : "transparent";
                    return <Box key={entrant.id} bgColor={ bgColor } px={3}>
                        <Divider orientation={"horizontal"}/>
                        <Entry
                            entrant={entrant}
                            contest={contest}
                            instagram_username={instagram_username}
                            idx={entrant.rank}/>

                    </Box>
                }
                )
            }
        </Box>
    )

}

export default LeadersList
