import { Box } from "@chakra-ui/react"

export const GridItem = ({ children, ...rest }) => (
    <Box
        { ...rest }
        w={"full"}
        maxW="sm"
        justifyItems="center"
        justifyContent="center"
        justifySelf={[ "center" ]}>
        {children}
    </Box>
)

export default GridItem
