import { useState, useEffect } from "react"

export const useSubscriber = props => {

    let { firebase, collections, entry, unmask } = props;

    let [subscriber, setSubscriber] = useState(null)


    useEffect(() => {
        let getSubscriber = async () => {
            let db = firebase.firestore(),
                col = db.collection(collections.subscribers),
                docRef = col.doc(entry.subscriber_id),
                snapshot = await docRef.get();

            if (snapshot.exists) setSubscriber(snapshot.data())

        }

        try {
            if (unmask && entry) getSubscriber()
        } catch (err) {console.log(err)}
    }, [entry, collections.subscribers, firebase, unmask])

    return subscriber
}

export default useSubscriber