import { useState, useEffect } from "react"

export const usePreviousEntries = props => {
    let { firebase, collections, date } = props;

    let [previousEntries, setPreviousEntries] = useState(null);

    let isEligible = entry => !entry.wins || entry.wins.length < 3

    useEffect(() => {
        if (!date) return;

        let getContestEntries = async () => {
            let db = firebase.firestore(),
                col = db.collection(collections.entries),
                entriesSnapshot = await col.where("date", "==", date).get(),
                entries = [];

            entriesSnapshot.forEach(entry => {
                let data = entry.data()
                if (isEligible(data)) {
                    entries.push({
                        ...data
                    })
                }



            })
            setPreviousEntries(entries)
        }
        try {getContestEntries()} catch (err) { console.log(err)}



    }, [firebase, collections.entries, date]);

    return { previousEntries }
}

export default usePreviousEntries
