import moment from "moment";

import GridItem from "../common/GridItem"
import Card from "../common/Card"
import CardHeader from "../common/CardHeader"
import CardFooter from "../common/CardFooter"

import LeadersList from "./LeadersList"


export const Leaderboard = props => {
    let { contest, entries, isPrevious, listSize } = props;

    let last_update = contest.last_update || moment();

    let leaderboard_list_size = listSize || 10,
        title = `🥇🥇 Top ${leaderboard_list_size} ${contest.label} 🥇🥇`,
        last_updated = moment(last_update.toDate()).format("YYYY년MM월DD일 HH:mm"),
        ended = moment(contest.end).format("YYYY년MM월DD일 HH:mm"),
        contest_date = isPrevious ? `Ended: ${ended}` : `Updated: ${last_updated}`;

    // title = isPrevious ? `${title} **지난번**` : title;
    let color = isPrevious ? "olympics.info" : "olympics.danger";

    return (
        <GridItem
            className={"leaderboard"}>
            <Card border={color}>
                <CardHeader title={title} subtitle={contest.description} isPrevious={ isPrevious }/>
                <LeadersList entries={entries} contest={contest} size={leaderboard_list_size}/>
                <CardFooter color={"gray.500"}>{contest_date}</CardFooter>
            </Card>
        </GridItem>
    )
}

export default Leaderboard
