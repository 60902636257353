import { Badge, Box, Flex } from "@chakra-ui/react";

export const Rank = ({ rank }) => (
    <Flex align={"center"}>
        <Box
            className="user-rank"
            textAlign={"center"}
            mr={3}
            width={5}
        >
            <Badge
                fontSize={"sm"}
                fontFamily={"Poppins"}
                fontWeight={"bold"}
                colorScheme={"gray"}
            >
                { rank }
            </Badge>
        </Box>
    </Flex>

)

export default Rank
