const colors = {
    p1: {
        50: "#ffeeeb",
        100: "#e7d2cc",
        200: "#d1b6ad", // standard
        300: "#be998d",
        400: "#ab7d6d",
        500: "#926354",
        600: "#724c41",
        700: "#52372e",
        800: "#33201a",
        900: "#170802",
    },
    p2:
        {
            50: '#ffecef',
            100: '#e6ced0',
            200: '#d0afb2',
            300: '#bc8f93', // standard
            400: '#a87074',
            500: '#8f575a',
            600: '#704346',
            700: '#513032',
            800: '#331c1d',
            900: '#190606',
        },
    p3:
        {
            50: '#feedf4',
            100: '#e5d0d9',
            200: '#cdb2be',
            300: '#b894a4', // standard
            400: '#a3758b',
            500: '#895c71',
            600: '#6b4758',
            700: '#4e333f',
            800: '#301d27',
            900: '#160610',
        },
    p4:
        {
            50: '#f8eefc',
            100: '#dfd2e2',
            200: '#c6b5ca',
            300: '#ae99b4',
            400: '#967c9d', // standard
            500: '#7c6283',
            600: '#624d67',
            700: '#46364a',
            800: '#2b202f',
            900: '#130815',
        },
    p5:
        {
            50: '#efeffe',
            100: '#d1d3e5',
            200: '#b3b6ce',
            300: '#9698b7',
            400: '#787ba1',
            500: '#5f6187', // standard
            600: '#4a4c6a',
            700: '#35364d',
            800: '#1e2031',
            900: '#0a0a17',
        },
    p6:
        {
            50: '#e4f4ff',
            100: '#c2dcf0',
            200: '#9fc3e0',
            300: '#7aabd2',
            400: '#5592c4',
            500: '#3c79ab',
            600: '#2d5e85', // standard
            700: '#1e4360',
            800: '#0d283c',
            900: '#000e1a',
        },
    danger:
        {
            50: '#ffe9e9',
            100: '#f0c6c7',
            200: '#dea2a4',
            300: '#cf7d80',
            400: '#c0595c',
            500: '#a63f43',
            600: '#823034',
            700: '#5e2224',
            800: '#3a1314',
            900: '#1b0303',
        },
    olympics: {
        red: "#ef476fff",
        danger: "#ef476fff",
        yellow: "#ffd166ff",
        warning: "#ffd166ff",
        green: "#06d6a0ff",
        success: "#06d6a0ff",
        blue: "#118ab2ff",
        info: "#118ab2ff",
        black: "#073b4cff",
        primary: "#073b4cff",
    },
    gradient: {
        green: "#36D1DC",
        blue: "#5B86E5"
    }
}

colors.brand = {
    biege: colors.p1["200"],
    brown: colors.p2["200"],
    pink: colors.p3["300"],
    purple: colors.p4["400"],
    slate: colors.p5["500"],
    blue: colors.p6["600"],
    red: colors.danger["400"],
    100: colors.p1["200"],
    200: colors.p2["200"],
    300: colors.p3["300"],
    400: colors.p4["400"],
    500: colors.p5["500"],
    600: colors.p6["600"]
}

export default colors